<template>
  <div class="newscl">
    <!-- header组件     -->
    <vheader :navId="navId"> </vheader>
    <!-- header组件     -->

    <!-- banner组件 -->
    <!-- <banner :navName="navName" :navUrl="navUrl"></banner> -->
    <div class="banner-box">
      <div class="banner-img"></div>
      <div class="banner-nav">
        <div class="banner-nav-content">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <span><i class="el-icon-location"></i>当前位置：</span>
            <el-breadcrumb-item :to="{ path: 'index' }" title="业务入口">网站首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'mediaCoverage' }" title="媒体报道">媒体报道</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'newscl1' }" title="新闻详情">新闻详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <!-- banner组件 -->
    <div class="newscl_w">
      <div class="newsContentBoxLeft">
        <h1 class="newsContentBoxLeftTitle" id="title">
          中关村新谈——恒安康泰首印象
        </h1>
        <p class="newsContentBoxLeftTime">
          <span>发布时间：2021-07-19 16:57:46</span><span>阅读：245</span><span>来自：恒安康泰新闻中心</span><span>编辑：刘春梅</span>
        </p>
        <hr class="newsContentBoxLeftFenxian" />
        <div id="frameContent">
          <p style="text-indent: 2em; line-height: 1.75em">
            入驻中关村创业园的盛浪还有余热，恒安康泰再次迎来中关村杂志的记者采访。那么近期中关村杂志新谈，又将以何种视角展现恒安康泰。不妨转至原文阅读——对话恒安康泰，了解一个有温度的互联网医疗平台。
          </p>
          <!-- <p style="line-height: 1.75em; text-align: center; text-indent: 0em">
            <img src="../../assets/img/mediaCoverage/1626685314.jpg" title="1626685314.jpg" alt="未标题-3.jpg" width="658"
              height="373" />
          </p> -->
          <p style="line-height: 1.75em; text-indent: 2em">
            “我建议您去北京做一个检查……”
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            “这个手术我们也可以做，但是北京的医院技术更成熟一些……”
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            北京，医疗资源丰富，有23所全国百强优质医院。2014年统计数据显示，北京外来就医人数达到了日均70万。北京医疗机构去年便承接了八千六百多万人的异地就医人员。其中，只有1.2%能挂上专家号。
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            这其中，“选北京的哪家医院？”、“怎样及时的挂到号？”、“来北京后如何安排？”……急迫、盲目、迷茫、孤立无援成为很多外地患者到北京就医不约而同的心声。
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            如何让异地就医变得简单，让赴京治疗更加便捷？恒安康泰（北京）医疗健康科技有限责任公司（以下简称“恒安康泰”）给出了完善全面，线上线下的多重解决方案。
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            “我们专注于北京地区的优质医疗资源，在充分尊重医疗行业特点的前提下，运用互联网的新思维及新技术，服务北方地区患者，设计开发北京看诊互联网医疗平台，简化大众的就医流程，提供在京期间温暖专业的就医辅助服务。我们致力于打造线上远程便捷和线下温馨服务相结合的患者信任、医生认可、有口皆碑的科技化专业医疗服务平台。”恒安康泰董事长贾琼告诉记者。
          </p>
          <p><br /></p>
          <p style="text-align: center">
            <img src="../../assets/img/mediaCoverage/1626685917.jpg" title="1626685917.jpg" alt="未标题-4.jpg" width="625"
              height="345" />
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            贾琼在2008年出国留学，用三年的时间完成了澳大利亚公立大学金融管理学（专业会计师）和智能信息系统学（商业信息系统）的两个硕士学位的学习，并成为澳大利亚注册会计师协会会员。学成毕业后，贾琼曾终身受聘于世界百强地产管理企业澳大利亚分公司。
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            2019年回国后，带着学术沉淀和十余年的国际企业高级管理经验，在怀揣着用一己之力为家乡做些微薄贡献的愿景下，贾琼与具有丰富互联网系统开发，软件设计，网络用户平台建设和管理经验的中国互联网发展早期的网络产品拓荒者，马飞，以及国内上市集团企业的高级管理成员共同创办了恒安康泰医疗健康科技平台。
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            “刚回国时其实有很多项目找到我们，之所以选择医疗服务领域，是因为与国外相比，我国的医疗资源分配相对不均衡，同等医疗资源的普惠生态体系还不是很完善，尤其是在我的家乡，在发展相对北京明显落后的西部。身边的朋友和亲人们的异地赴京就诊经历让我体会到在异地，尤其是在北京这样优质医疗资源集中的地区，作为一名外地患者，就医过程中需要处理大大小小的问题，有着各种各样的实际困难和不便。
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            既然选择了创办企业，我希望我们的创业能够为社会服务，为西部服务，为家乡人做出一点实际的贡献。我们的平台将协助北方的每一位普通大众突破地域的限制，利用科技的手段享受互联网远程医疗的便捷，同时，将互联网产品在人们心中一贯的概念——虚拟性进行了补充和解决。我们增加了多样化的线下配套服务：专人陪诊、专业导诊、工作人员协助预约、酒店、机场、医院的全程接送、北京地区就诊期间的酒店安置等等切实落地的人文关怀服务，增加了大家与北京地区的知名医师直接沟通的机会，减少了大家在北京医院做医疗检查的困难和负担。
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            希望我们可以推动北方地区，尤其是西部地区‘互联网+医疗健康’的建设完善和稳步发展。”
          </p>
          <p><br /></p>
          <p style="text-align: center">
            <img src="../../assets/img/mediaCoverage/1626685646.jpg" title="1626685646.jpg" alt="未标题-3.jpg" width="680"
              height="342" />
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            马飞是在中国的互联网行业刚刚踊跃兴起的年代参与到网络行业中来的，是中国最早的互联网平台和互联网科技产品的建设者，也是中国网络平台产品的拓荒者之一。
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            2009年到2019年，在澳大利亚专注互联网工作十年。马飞凭借着对互联网产品开发建设的夯实基础和丰富经验，自筹自建了澳大利亚麦琪国际互联网传媒公司（MQ
            International Internet
            Media），先后服务于多国企业在澳大利亚的互联网产品定制和线上宣传工作。就在回国之前，马飞正就职于澳大利亚最大的养老社区互联网建设中心，AVEO养老集团。
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            经过前期的细致规划和耐心准备，恒安康泰陆续推出了北京地区各大医院查询，北京医生信息查询、在线远程图文问诊、电话问诊、视频门诊、门诊精准预约、诊后疾病管理、家庭医生、慢性病知识科普等多达35项的线上线下服务。用户可以通过恒安康泰APP，公司官方网站(www.henganmedicare.com)的电脑端、手机端、微信公众号“恒安康泰医疗健康”和微信小程序等多个平台随时访问和体验便捷快速医疗服务，链接到北京地区三级甲等公立医院的医生，“一站式”解决线上就医问诊、线下赴京门诊等各种医疗问题。
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            恒安康泰还计划在西部偏远地区建立“远程专家门诊”和“家庭医生签约服务”，以推动西部地区的“互联网+医疗健康”发展，为西部偏远地区的人民提供足不出户即可享受到国家优质医疗资源的就医平台，推动和加强西部偏远地区人民的健康水平建设，提高医疗保障，解决异地看病难，就医难的典型突出问题。
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 0em; text-align: center">
            <img src="../../assets/img/mediaCoverage/1626682929.jpg" title="1626682929.jpg"
              alt="5a1b5faaf27d4a6b9172f00c59073d69.jpg" width="671" height="369" />
          </p>
          <p style="line-height: 1.75em; text-indent: 0em"><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            目前，成立一年有余的恒安康泰公司针对西部来京就医难的问题，已经建立了一条温暖便捷的连接外地就医和北京医院的绿色通道。
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            恒安康泰已就异地患者到50多家北京三甲医院看诊的难点痛点进行统计和分析，为外地来京的就医人员在北京当地可提供快捷及时的多种帮助：预约挂号，医疗检查，名医诊断，代看检查报告，代看各种影像检查结果，以及温馨细致的接送和行程安排，并为患者提供二次复诊的预约保证。
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            为了保障服务可以普惠到更多的患者，恒安康泰还与多家金融机构合作，为有需要的客户提供医疗金融解决方案。
          </p>
          <p><br /></p>
          <p style="line-height: 1.75em; text-indent: 2em">
            家住内蒙古的李先生父亲是过敏性体质，出现急发性肠炎。由于身体对多类药物过敏，当地医院在患者住院的情况下无法确定药物的使用，炎症得不到抑制，医生建议前往北京转院。李先生紧急联系了恒安康泰会员服务中心。恒安康泰的工作人员根据李先生的行程预期，协助李先生办理了解放军301医院在对应科室的副主任医师的预约挂号，次日即可就诊。
          </p>
          <p><br /></p>
        </div>
      </div>
      <div class="listNewsRight">
        <h2 class="stateNewsRighth2">健康热点资讯</h2>
        <hr class="stateNewsRight_h2_hr" />
        <h3 class="stateNewsRighth3">
          <a href="" title="国务新闻">国务新闻</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[0]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <!-- <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu1.jpg" alt="" title="恒安康泰医疗" /></a> -->
        <h3 class="stateNewsRighth3">
          <a href="" title="名医出诊">名医出诊</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[1]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康养生">健康养生</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[2]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu2.jpg" alt="" title="恒安康泰医疗" /></a>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康资讯">健康资讯</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[3]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
      </div>
    </div>
    <vfooter></vfooter>
    <GoTop />
  </div>
</template>
<script>
// import banner from "../../components/banner.vue";
import vheader from "../../components/vheader.vue";
import vfooter from "../../components/vfooter.vue";
import GoTop from "@/components/GoTop.vue";
export default {
  name: 'newscl9',
  data() {
    return {
      navName: '新闻详情',
      navUrl: 'newscl9',
      navId: 5,
      rilist1: [
        [
          { listit: "2020年全国居民健康素养水平达23.15%", lishref: "#" },
          { listit: "癌症治疗新希望！我国首个自主研发...", lishref: "#" },
          { listit: "国家卫健委出台人感染动物源性流感...", lishref: "#" },
          { listit: "国家医保局发文，明确推进门诊费用...", lishref: "#" },
          { listit: "国家卫健委：加强儿童中医药服务 推...", lishref: "#" },
          { listit: "这家西南的医院用高品质医疗服务，建...", lishref: "#" },
          { listit: "“十四五”残疾人康复服务蓝图绘就", lishref: "#" },
          { listit: "《2021医生互联网诊疗行为洞察报告》...", lishref: "#" },
          { listit: "六大方面布局！国办发文推动公立医院...", lishref: "#" },
          { listit: "七大城市用户调研指出：智慧医疗未来...", lishref: "#" },
        ],
        [
          { listit: "中国人民解放军总医院院士合集（部分）", lishref: "#" },
          { listit: "U医公益行启动，让泌尿疾病患者在基层...", lishref: "#" },
          { listit: "第十八期“院士+”西部儿科医师培训班座...", lishref: "#" },
          { listit: "福建首例机器人无腹部切口大肠癌手术在...", lishref: "#" },
          { listit: "多位专家赴湖北十堰参加燃气爆炸紧急医...", lishref: "#" },
          { listit: "张楠：阿尔茨海默病患者护理更需专业指导", lishref: "#" },
          { listit: "第一届智慧医疗与医学人文高峰论坛暨医...", lishref: "#" },
          { listit: "提醒身边长痣的朋友，出现以下五种症状...", lishref: "#" },
          { listit: "“疫”路同行的夫妻档", lishref: "#" },
          { listit: "与北京社团合作，中国西部城市乌兰察布...", lishref: "#" },
        ],
        [
          { listit: "水培蔬菜社交网络走红 专家：较土培更...", lishref: "#" },
          { listit: "山药的做法 山药的功效与作用", lishref: "#" },
          { listit: "发青的鸡蛋黄致癌？ 变绿部分无毒，可...", lishref: "#" },
          { listit: "经常失眠，可能是体内缺什么？3种营养...", lishref: "#" },
          { listit: "你知道健康吃饭的黄金标准吗？", lishref: "#" },
          { listit: "5大习惯能『防癌』，很多人都忽视了", lishref: "#" },
          { listit: "每餐4-6小时的间隔期最符合人体消化需...", lishref: "#" },
          { listit: "晨起一杯水别这么喝，风险太大...教你...", lishref: "#" },
          { listit: "心脏有问题，为什么医生还建议让我多运...", lishref: "#" },
          { listit: "缺铁性贫血，补就完事？补血食物别踩坑", lishref: "#" },
        ],
        [
          { listit: "疾控专家邵一鸣对我国目前疫苗功效给出解读", lishref: "#" },
          {
            listit: "馒头、米饭哪个更容易升血糖？一对比，3餐...",
            lishref: "#",
          },
          { listit: "手抖也能通过外科手术治愈啦", lishref: "#" },
          { listit: "身体血糖高 多喝水好还是少喝水好？看完...", lishref: "#" },
          { listit: "练起来 打太极拳能减“游泳圈”", lishref: "#" },
          { listit: "【科学就医我行动】陈勇皮科问答 | 10.皮...", lishref: "#" },
          { listit: "维生素C有什么功效和副作用？", lishref: "#" },
          {
            listit: "“提高免疫力”，被过度夸大化，免疫平衡比“...",
            lishref: "#",
          },
          { listit: "胃癌如何选择最合适疗方案？CA最新刊文指...", lishref: "#" },
          { listit: "九价HPV疫苗为何“一针难求”", lishref: "#" },
        ],
      ],
    };
  },
  components: {
    // banner,
    vheader,
    vfooter,
    GoTop,
  },
};
</script>
<style lang="less">
@media screen and (min-width:750px) {
  .banner-box {
    .banner-img {
      height: 280Px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 100%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 1200Px;
        margin: auto;

        .el-breadcrumb {
          line-height: 70Px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 20Px;
            margin-right: 12Px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}

@media screen and (max-width:750px) {
  .banner-box {
    .banner-img {
      height: 110px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 200%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 95%;
        margin: auto;

        .el-breadcrumb {
          line-height: 35px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 19px;
            margin-right: 2px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}
</style>